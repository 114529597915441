import React from "react"
import "./style.scss"

const Toggle = ({ data, handleCheck }) => (
  <div className="toggle">
    <label className="input-check">
      <input type="checkbox" checked={data.value} onChange={handleCheck} />
      <span className="background-slider"></span>
      <span
        className={
          data.value === false
            ? "toggle-text one-time-active"
            : "toggle-text one-time-inactive"
        }
      >
        One time
      </span>
      <span
        className={
          data.value === false
            ? "toggle-text monthly-inactive"
            : "toggle-text monthly-active"
        }
      >
        Monthly
      </span>
    </label>
  </div>
)

export default Toggle
