import React, { Component } from "react"
import "@components/_molecules/modal/style.scss"
import { Title1A } from "@components/_atoms/Text"

interface IComponentState {}
interface IComponentProps {
  data: {
    title: string
    copy: string
  }
  onCancel: () => void
  onConfirm: () => void
}

class ConfirmModal extends Component<IComponentProps, IComponentState> {
  render() {
    const { data } = this.props
    return (
      <div className="modal-container-background">
        <div className="confirm-modal custom-modal">
          <Title1A className="header text-spacing">{data.title}</Title1A>
          <p className="body">{data.copy}</p>
          <button
            className="button-cancel shared-button-styling"
            onClick={this.props.onCancel}
          >
            Cancel
          </button>
          <button
            className="ok-button shared-button-styling"
            onClick={this.props.onConfirm}
          >
            OK
          </button>
        </div>
      </div>
    )
  }
}

export default ConfirmModal
