import React, { Component } from "react"
import axios from "axios"
import * as headers from "@data/headers"
import * as data from "@data/pledge"
import RadioButton from "@components/_atoms/button_radio"
import FormButton from "@components/_atoms/button_form_input_submit"
import ConfirmModal from "@components/_molecules/modal_confirm"
import "./style.scss"
import * as progress from "@services/progress"
import * as errors from "@services/errors"
import {
  PARENT_REGISTRATION_STEP_INVITE,
  PARENT_REGISTRATION_LS_KEY_INITIAL_DEPOSIT
} from "@data/constants"
import Toggle from "@components/_atoms/toggle"

const isClient = typeof window !== "undefined"
const RANGE = [
  0,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  55,
  60,
  65,
  70,
  75,
  80,
  85,
  90,
  95,
  100
]
class GraphicalInvestForecast extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pledge: 1,
      monthly: false,
      rangeVisibility: 0,
      displayValue: 10,
      checkError: "no-error",
      generalError: null,
      buttonSubmitted: false,
      confirm: null,
      ask: false
    }
    this.handleConfirm = this.handleConfirm.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  compoundInterestCalc = (principle, term, rate) =>
    principle * Math.pow(1 + rate / 12, term)

  compoundInterestWContribution = (principle, term, rate) =>
    Math.floor(
      this.compoundInterestCalc(principle, term, rate) +
        (principle * (this.compoundInterestCalc(1, term, rate) - 1)) /
          (rate / 12)
    )
  handleChange = ev => {
    if (
      ev.target.type === `range` ||
      (ev.target.name === `pledge` && ev.target.value === `custom`)
    ) {
      let rangeVisibility = 1
      this.setState({ rangeVisibility })
    } else {
      let rangeVisibility = 0
      this.setState({ rangeVisibility })
    }
    if (ev.target.name === `pledge` && ev.target.value === `custom`) {
      let pledge = 9
      let displayValue = RANGE[pledge]
      this.setState({ pledge, displayValue })
    } else if (ev.target.name === `pledge` && ev.target.value !== `custom`) {
      let pledge = ev.target.value
      let displayValue = RANGE[pledge]
      this.setState({ pledge, displayValue })
    }
  }

  handleSubmit = ev => {
    ev.preventDefault()
    this.setState({ buttonSubmitted: true })
    if (parseInt(this.state.displayValue) !== 0) {
      if (this.state.monthly === true) {
        this.postPledge()
      } else {
        this.postOneTime()
      }
    } else {
      this.setState({ ask: true })
    }
  }

  postPledge = () => {
    let _this = this, //needed to avoid losing context during axios/fetch call
      query = {
        query: `mutation($createUserRecurringDepositInput: CreateUserRecurringDepositInput!) {
            createUserRecurringDeposit(input: $createUserRecurringDepositInput ){
              userRecurringDeposit {
                amount
                paymentAccountId
                paymentInterval
                userId
              }
            }
          }`,
        variables: `{
            "createUserRecurringDepositInput":{
              "recurringDeposit":{
                "amount":"${_this.state.displayValue}",
                "paymentInterval":"monthly"
              }
            }
          }`
      }
    const options = {
      method: "POST",
      data: JSON.stringify(query),
      headers: headers.getHeaders(),
      url: headers.api
    }
    axios(options)
      .then(res => {
        if (res.data.errors) {
          _this.errorHandler(res.data.errors[0].message)
        } else {
          isClient &&
            window.localStorage.setItem(
              PARENT_REGISTRATION_LS_KEY_INITIAL_DEPOSIT,
              _this.state.displayValue
            )
          progress.updateProgress()
          window.location = `/pledge/register/${progress.getNext()}`
        }
      })
      .catch(function(error) {
        _this.setState({ buttonSubmitted: false })
        console.log(error)
      })
  }
  postOneTime = () => {
    let _this = this,
      createOneTime = {
        query: `mutation($createUserOneTimeDepositInput: CreateUserOneTimeDepositInput!) {
          createUserOneTimeDeposit(input: $createUserOneTimeDepositInput) {
            userOneTimeDeposit {
              id
              userId
              amount
              description
              scheduledAt
              depositCreatedAt
              deletedAt
              createdAt
              updatedAt
            }
          }
        }`,
        variables: {
          createUserOneTimeDepositInput: {
            userOneTimeDeposit: {
              amount: _this.state.displayValue.toString()
            }
          }
        }
      }
    const options = {
      method: "POST",
      data: JSON.stringify(createOneTime),
      headers: headers.getHeaders(),
      url: headers.api
    }
    axios(options)
      .then(res => {
        if (res.data.errors) {
          _this.errorHandler(res.data.errors[0].message)
        } else {
          isClient &&
            window.localStorage.setItem(
              PARENT_REGISTRATION_LS_KEY_INITIAL_DEPOSIT,
              _this.state.displayValue
            )
          progress.updateProgress()
          window.location = `/pledge/register/${progress.getNext()}`
        }
      })
      .catch(function(error) {
        _this.setState({ buttonSubmitted: false })
        console.log(error)
      })
  }

  errorHandler = err => {
    this.setState({
      buttonSubmitted: false,
      generalError: errors.messaging(err)
    })
  }
  handleConfirm() {
    this.setState({
      ask: false
    })
    progress.updateProgress()
    const nextStep = progress.getNext()
    if (progress.hasInvite) {
      if (isClient) {
        window.location = `/pledge/register/${PARENT_REGISTRATION_STEP_INVITE}`
      }
    } else {
      progress.regComplete()
    }
  }
  handleCancel() {
    this.setState({ buttonSubmitted: false, ask: false })
  }
  handleCheck = () => {
    if (this.state.monthly === false) {
      this.setState({ monthly: true })
    } else {
      this.setState({ monthly: false })
    }
  }
  render() {
    const { supportSelection } = data
    return (
      <div className="forecaster">
        {this.state.ask && (
          <ConfirmModal
            data={supportSelection.selectSupportModal}
            onConfirm={this.handleConfirm}
            onCancel={this.handleCancel}
          />
        )}

        <div>
          <div className="monthly-contribution">{this.state.displayValue}</div>
        </div>
        <form
          action={headers.api}
          className="pledge-chooser"
          onChange={this.handleChange.bind(this)}
          onSubmit={this.handleSubmit.bind(this)}
        >
          <div className="radio-row">
            <RadioButton
              data={{
                id: "fifty",
                name: `pledge`,
                value: 1,
                defaultChecked: true
              }}
            >
              $10
            </RadioButton>
            <RadioButton data={{ id: "hundred", name: `pledge`, value: 9 }}>
              $50
            </RadioButton>
            <RadioButton
              data={{ id: "hundredfifty", name: `pledge`, value: 14 }}
            >
              $75
            </RadioButton>
            <RadioButton
              data={{ id: "custom", name: `pledge`, value: `custom` }}
            >
              Custom
            </RadioButton>
          </div>
          <div className="slider-wrap">
            {this.state.rangeVisibility ? (
              <input
                type="range"
                min="0"
                max="19"
                name="pledge"
                value={this.state.pledge}
                onChange={this.handleChange.bind(this)}
                style={{
                  opacity: this.state.rangeVisibility,
                  visibility: this.state.rangeVisibility ? `visible` : `hidden`
                }}
              />
            ) : (
              <div></div>
            )}
          </div>
          <Toggle
            data={{ value: this.state.monthly }}
            handleCheck={this.handleCheck}
          />

          <div className="input-placeholder">
            <p>
              Money invested in the market has grown 20x more than in savings
              over the last 40 years.
            </p>
          </div>
          <FormButton
            text={"Confirm"}
            data={supportSelection.supportSelectionButton}
            isDisabled={this.state.buttonSubmitted}
          />
          <p className={this.state.checkError}>
            The value you selected is not correct!
          </p>
        </form>
      </div>
    )
  }
}

export default GraphicalInvestForecast
