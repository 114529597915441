import React from "react"

import SEO from "@components/seo"
import * as data from "@data/pledge"
import Layout from "@components/layout"
import BackButton from "@components/back_button"
import HeaderAndText from "@components/header_with_text"
import GraphicalInvestForecast from "@components/_molecules/graphical_invest_forecast"
import ProgressBar from "@components/_atoms/progress_bar_reg"

const SelectSupportPage = () => {
  const { supportSelection } = data
  return (
    <Layout type="gray" pageClass="pledge">
      <ProgressBar />
      <div style={{ height: `48px`, width: `100%`, display: `block` }}></div>
      <SEO title={supportSelection.title} />
      <HeaderAndText data={supportSelection.heading} />
      <GraphicalInvestForecast />
    </Layout>
  )
}

export default SelectSupportPage
